<template>
  <div class="content">
    <article
      class="row"
      style="margin-top: 10px; background: linear-gradient(#1f1f1f,black,#1f1f1f)"
      :style="{ borderTop: `2px solid ${this.secondaryColor}` }"
    >

      <section class="col-lg-8 col-sm-12">
      <h1 class="tulum-party-title" style="font-size: 3.4rem">TULUM MEMORIES</h1>
      <p>
        We've curated a collection of videos that capture the essence of Tulum    </p>
        <p>Grab your popcorn & dim the lights</p>
      <div>
        <router-link :to="{name: 'Artist', params: {slug: video.slug} }" style="font-size: 1rem;margin: 0.7rem;">
          <div v-if="video.metadata.videoid" style="min-height: 300px">
            <video-card :video-id="video.metadata.videoid"
                        width="100%"
                        style="padding: 10px;"
                        mtv
                        height="240px"
                        :title="video.metadata.videotitle"
                        :caption="video.metadata.videocaption">
            </video-card>
          </div>
        </router-link>
      </div>
        <h3 class="tulum-party-subtitle text-smart" style="font-size: 2rem">Featured Videos</h3>
      <div class="row"  style="min-height: 200px">
        <div v-for="(card, idx) in featured" :key="card.title" class="col-sm-12" >
          <DynamicCard :slug="card.slug" notitle :key="idx"></DynamicCard>
        </div>
      </div>
        <h4 class="tulum-party-subtitle text-smart">You may be interested</h4>
<!--      <div class="row" style="min-height: 200px">-->
<!--        <div v-for="(card, idx) in suggestions" :key="card.title" class="col-sm-4" style="min-height: 200px">-->
<!--          <DynamicCard :slug="card.slug" notitle :key="idx"></DynamicCard>-->
<!--        </div>-->
<!--      </div>-->
      </section>
      <aside class=" col-sm-4 col-lg-4 d-none d-lg-inline text-left">
        <h3 class="section-header">Upcoming</h3>

        <HotThisWeek primary-color="#4a4747"  align="text-center"></HotThisWeek>


        <p style="font-size: 0.8rem">
          <br>Few places truly square up to Tulum in terms of its exclusivity and range of prolific high-profile Djs and Producers showing up in town week by week .
        </p>
        <hr>

        <DjBioCollection></DjBioCollection>

      </aside>

    </article>
  </div>

</template>

<script>
import VideoCard from "@/components/Cards/VideoCard";
import DynamicCard from "@/components/Cards/DynamicCard";

export default {
  name: 'Tulumtv',
  metaInfo() {
    return {
      title: `TULUMTV - Videos, Reviews, Dj Sets & More`,
      meta: [
        {
          name: 'description',
          content: `Tulum Videos, Articles & Reviews`,
        },
        {
          name: 'keywords',
          content: `Videos, Reviews, Dj Sets, Clubs, News`,
        },
      ],
    };
  },
  props: ['slug'],
  data: () => {
    return {
    };
  },
  components: {
    DynamicCard,
    VideoCard,
  },
  created: function () {
    this.$store.dispatch('getTulumTv', {start: 0});
  },
  computed: {
    total: {
      get() {
        return this.$store.state.total;
      },
    },
    video: {
      get() {
        return this.$store.state.tulumVideos.artist;
      },
    },
    featured: {
      get() {
        return this.$store.state.tulumVideos.featured;
      },
    },
    suggestions: {
      get() {
        return this.$store.state.tulumVideos.suggestions;
      },
    },

  }
}
</script>

